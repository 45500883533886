import React from 'react';
import classNames from 'classnames';
import ErrorIcon from './icons/ErrorIcon';
import Button from './Button';

function Error() {
  return (
    <div
      className={classNames(
        'flex flex-col items-center justify-center',
        'bg-[lightgray]',
        'font-sans text-body text-jet',
        'border-2 border-r-[gray] border-b-[gray] border-l-[white] border-t-[white]',
        'max-w-sm mx-auto',
        'z-10',
      )}>
      <div
        className={classNames(
          'flex items-center justify-between',
          'w-full h-8 mb-2 px-2',
          'bg-keppel',
        )}>
        <span className={classNames('font-bold text-[white]')}>Error</span>
        <button
          className={classNames(
            'text-[gray] text-xl leading-none',
            'w-6 h-6',
            'border-r-4 border-r-[gray] border-b-4 border-b-[gray] border-l-2 border-l-[white] border-t-2 border-t-[white]',
            'bg-[lightgray]',
          )}>
          &times;
        </button>
      </div>
      <div className={classNames('flex flex-col', 'w-full mb-2 px-4 py-2')}>
        <div className="flex gap-4 items-top">
          <ErrorIcon />
          <p className="mb-4 text-left">
            You haven't been styled by Carly yet!
          </p>
        </div>

        <Button
          className={'self-end'}
          onClick={() => {
            window.location.href =
              'https://docs.google.com/forms/d/e/1FAIpQLSdat7k-1Pb_rrxu5T0qI_DLFmZc19Ec0G_CUWKyoYvIU7_17g/viewform';
          }}>
          Style Me
        </Button>
      </div>
    </div>
  );
}

export default Error;
