import React from 'react';
import classNames from 'classnames';

function Button({ onClick, className, children }) {
  return (
    <button
      className={classNames(
        'px-4 py-1',
        'w-max',
        'border-r-4 border-r-[gray] border-b-4 border-b-[gray] border-l-2 border-l-[white] border-t-2 border-t-[white]',
        'bg-[lightgray] hover:bg-blossom hover:text-[white] transition-colors',
        className,
      )}
      onClick={onClick}>
      {children}
    </button>
  );
}

export default Button;
