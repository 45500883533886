import React from 'react';
import instagramLogo from './icons/InstagramLogo.svg';

const InstagramButton = ({ profileTag }) => (
  <button
    onClick={() =>
      window.open(
        `https://www.instagram.com/${profileTag}`,
        '_blank',
        'noopener,noreferrer',
      )
    }
    className="w-max drop-shadow-md hover:brightness-110 hover:!drop-shadow-lg">
    <img src={instagramLogo} alt="Instagram" className="w-8 h-8" />
  </button>
);

export default InstagramButton;
