import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import * as d3 from 'd3';

import Heart from './Heart';
import classNames from 'classnames';

const MAX_FORCE_DISTANCE = 125; // Max distance where force is applied
const FORCE_MULTIPLIER = -10; // Controls how strong the force exerted is
const DAMPING = 0.2; // Controls friction-like behavior (0.9 means 90% resistance)
const FPS = 29.95; // Frame rate for animation

export const HeartPatternBackground = ({ density = 1 }) => {
  const svgRef = useRef(null);
  const requestRef = useRef(null); // For tracking requestAnimationFrame
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Handle resize events
    // TODO figure out how to handle scrolling on mobile
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    const svg = d3.select(svgRef.current);

    // Remove any existing elements from the SVG (in case of remounts)
    svg.selectAll('*').remove();

    const GRID_ROWS = Math.floor(dimensions.height / (35 / density));
    const GRID_COLS = Math.floor(dimensions.width / (35 / density));

    // Create data for grid of hearts
    const data = d3.range(GRID_ROWS * GRID_COLS).map((d) => ({
      originalX:
        (d % GRID_COLS) * (dimensions.width / GRID_COLS) +
        dimensions.width / (2 * GRID_COLS),
      originalY:
        Math.floor(d / GRID_COLS) * (dimensions.height / GRID_ROWS) +
        dimensions.height / (2 * GRID_ROWS),
      x:
        (d % GRID_COLS) * (dimensions.width / GRID_COLS) +
        dimensions.width / (2 * GRID_COLS),
      y:
        Math.floor(d / GRID_COLS) * (dimensions.height / GRID_ROWS) +
        dimensions.height / (2 * GRID_ROWS),
      vx: 0, // Initial velocity x
      vy: 0, // Initial velocity y
    }));

    // Append a single <g> element to contain all hearts
    const gridGroup = svg.append('g');

    // Create placeholders for each heart using foreignObject within the single <g> element
    const grid = gridGroup
      .selectAll('foreignObject')
      .data(data)
      .enter()
      .append('foreignObject')
      .attr('width', 128 / density) // Size of the hearts adjusted by density
      .attr('height', 128 / density)
      .attr('x', (d) => d.x - 15 / density) // Center the hearts
      .attr('y', (d) => d.y - 15 / density); // Center the hearts

    // Render the Heart component into each foreignObject
    grid.each(function (d, i) {
      const node = this;
      ReactDOM.render(<Heart size={'h-4 w-4'} />, node);
    });

    // Simulate physics for each frame using requestAnimationFrame
    const simulate = () => {
      grid.each(function (d) {
        d.vx *= DAMPING; // Apply damping/friction to velocity
        d.vy *= DAMPING;

        d.x += d.vx;
        d.y += d.vy;

        // Gradually return to the original position
        const springForceX = (d.originalX - d.x) * 0.05;
        const springForceY = (d.originalY - d.y) * 0.05;

        d.vx += springForceX;
        d.vy += springForceY;

        // Calculate distance from the original position
        const distanceFromOriginal = Math.sqrt(
          Math.pow(d.x - d.originalX, 2) + Math.pow(d.y - d.originalY, 2),
        );

        /*
        // FIXME Cannot use opacity in a foreignObject
        
        // Ref: https://bugs.webkit.org/show_bug.cgi?id=23113
        // Ref: https://github.com/bkrem/react-d3-tree/issues/284

        // Calculate opacity based on distance from the original position
        const opacity = Math.max(
          0.2,
          Math.min(0.8, distanceFromOriginal / MAX_FORCE_DISTANCE),
        );

        // Apply the opacity to the foreignObject
        d3.select(this).style('opacity', opacity);
        */

        // Update the heart position
        d3.select(this)
          .attr('x', d.x - 15 / density)
          .attr('y', d.y - 15 / density); // Center hearts
      });

      setTimeout(() => {
        requestRef.current = requestAnimationFrame(simulate);
      }, 1000 / FPS);
    };

    // Mouse move and touch events to exert force on the hearts
    const handleInteractionMove = (x, y, scrollY = 0) => {
      grid.each(function (d) {
        const dx = x - d.x;
        const dy = y - scrollY - d.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < MAX_FORCE_DISTANCE) {
          const force = (MAX_FORCE_DISTANCE - distance) / MAX_FORCE_DISTANCE;

          // Apply the force to the velocity
          d.vx -= (dx / distance) * force * FORCE_MULTIPLIER;
          d.vy -= (dy / distance) * force * FORCE_MULTIPLIER;
        }
      });
    };

    const handleMouseMove = (event) => {
      const [mouseX, mouseY] = d3.pointer(event, svgRef);
      handleInteractionMove(mouseX, mouseY, window.scrollY);
    };

    const handleTouchMove = (event) => {
      const touch = event.touches[0]; // Get the first touch point
      handleInteractionMove(touch.clientX, touch.clientY);
    };

    // Mouse leave and touch end to reset velocities
    const handleInteractionEnd = () => {
      grid.each(function (d) {
        d.vx = 0;
        d.vy = 0;
      });
    };

    // Attach event listeners to the SVG
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('mouseleave', handleInteractionEnd);
    window.addEventListener('touchend', handleInteractionEnd);

    // Start the physics simulation
    requestRef.current = requestAnimationFrame(simulate);

    // Cleanup event listeners and stop the animation loop on unmount
    return () => {
      cancelAnimationFrame(requestRef.current);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('mouseleave', handleInteractionEnd);
      window.removeEventListener('touchend', handleInteractionEnd);
    };
  }, [dimensions, density]);

  return (
    <svg
      ref={svgRef}
      className={classNames(['fixed w-full h-full', 'opacity-20'])}
    />
  );
};

export default HeartPatternBackground;
