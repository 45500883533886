import CyberGirlsHighlight from './photoshoots/C Y B E R G I R L S/1.JPG';
import GreenGoddessHighlight from './photoshoots/G R E E N G O D D E S S/1.jpg';
import OffGridHighlight from './photoshoots/O F F G R I D/1.JPG';
import OverpassHighlight from './photoshoots/O V E R P A S S/1.jpg';
import PurpleHazeHighlight from './photoshoots/P U R P L E H A Z E/1.jpg';
import AbyssHighlight from './photoshoots/A B Y S S/1.jpg';

function importAllImages(r) {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace('./', '')] = r(item);
  });
  return Object.values(images);
}

const cyberGirlsImages = importAllImages(
  require.context(
    './photoshoots/C Y B E R G I R L S',
    false,
    /\.(jpg|jpeg|JPG|JPEG)$/,
  ),
);
const greenGoddessImages = importAllImages(
  require.context(
    './photoshoots/G R E E N G O D D E S S',
    false,
    /\.(jpg|jpeg|JPG|JPEG)$/,
  ),
);
const offGridImages = importAllImages(
  require.context(
    './photoshoots/O F F G R I D',
    false,
    /\.(jpg|jpeg|JPG|JPEG)$/,
  ),
);
const overpassImages = importAllImages(
  require.context(
    './photoshoots/O V E R P A S S',
    false,
    /\.(jpg|jpeg|JPG|JPEG)$/,
  ),
);
const purpleHazeImages = importAllImages(
  require.context(
    './photoshoots/P U R P L E H A Z E',
    false,
    /\.(jpg|jpeg|JPG|JPEG)$/,
  ),
);
const abyssImages = importAllImages(
  require.context('./photoshoots/A B Y S S', false, /\.(jpg|jpeg|JPG|JPEG)$/),
);

export const photoshoots = {
  'cyber-girls': {
    highlight: CyberGirlsHighlight,
    images: cyberGirlsImages,
    title: 'C Y B E R G I R L S',
    models: [
      { name: 'Leah Pedroarena', ig: 'leahhskye' },
      { name: 'Yanie Diaz', ig: 'cluelessyan' },
      { name: 'Carly Janine', ig: 'carly.janine' },
    ],
    photographer: { name: 'Chloe Richardson', ig: 'wickedwurld' },
    stylist: { name: 'Carly Janine', ig: 'carly.janine' },
  },
  'green-goddess': {
    highlight: GreenGoddessHighlight,
    images: greenGoddessImages,
    title: 'G R E E N G O D D E S S',
    models: [{ name: 'Carly Janine', ig: 'carly.janine' }],
    photographer: { name: 'Chloe Richardson', ig: 'wickedwurld' },
    stylist: { name: 'Carly Janine', ig: 'carly.janine' },
  },
  'off-grid': {
    highlight: OffGridHighlight,
    images: offGridImages,
    title: 'O F F G R I D',
    models: [{ name: 'Leah Pedroarena', ig: 'leahhskye' }],
    photographer: { name: 'Chloe Richardson', ig: 'wickedwurld' },
    stylist: { name: 'Carly Janine', ig: 'carly.janine' },
  },
  overpass: {
    highlight: OverpassHighlight,
    images: overpassImages,
    title: 'O V E R P A S S',
    models: [{ name: 'Maddy Skye', ig: 'skye3bluu' }],
    photographer: { name: 'Chloe Richardson', ig: 'wickedwurld' },
    stylist: { name: 'Carly Janine', ig: 'carly.janine' },
  },
  'purple-haze': {
    highlight: PurpleHazeHighlight,
    images: purpleHazeImages,
    title: 'P U R P L E H A Z E',
    models: [{ name: 'Maddy Skye', ig: 'skye3bluu' }],
    photographer: { name: 'Chloe Richardson', ig: 'wickedwurld' },
    stylist: { name: 'Carly Janine', ig: 'carly.janine' },
  },
  abyss: {
    highlight: AbyssHighlight,
    images: abyssImages,
    title: 'A B Y S S',
    models: [{ name: 'Carly Janine', ig: 'carly.janine' }],
    photographer: { name: 'Chloe Richardson', ig: 'wickedwurld' },
    stylist: { name: 'Carly Janine', ig: 'carly.janine' },
  },
};
