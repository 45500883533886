import { PhotoshootHighlight } from './PhotoshootHighlight.js';
import classNames from 'classnames';
import CarlyJanine from './icons/Title.png';
import { photoshoots } from './photoshoots.js';
import Error from './Error.js';
import React from 'react';
// import SqueezeStretchImage from './SqueezeStretchImage.js';

function Home() {
  return (
    <React.Fragment>
      <div className={classNames('flex justify-center')}>
        {/* <SqueezeStretchImage src={CarlyJanine} width={800} height={600} /> */}
        <img
          src={CarlyJanine}
          className={classNames('mt-8 sm:mt-0 sm:w-1/2')}
        />
      </div>
      <Error />
      <div
        className={classNames('m-8', 'flex flex-wrap gap-14 justify-center')}>
        {Object.keys(photoshoots).map((photoshootKey) => (
          <PhotoshootHighlight
            key={photoshootKey}
            title={photoshoots[photoshootKey].title}
            path={photoshootKey}>
            <img
              src={photoshoots[photoshootKey].highlight}
              alt={photoshoots[photoshootKey].title}
              className={classNames('')}
            />
          </PhotoshootHighlight>
        ))}
      </div>
    </React.Fragment>
  );
}

export default Home;
