import React from 'react';
import classNames from 'classnames';
import InstagramButton from './InstagramButton';

const Header = ({ logo, links, buttonText, buttonClick }) => {
  return (
    <header className="fixed w-full top-0 z-50 p-4 flex justify-between items-center">
      <div className="flex items-center">
        <a
          href={'/'}
          className={classNames(
            'font-sans text-label text-jet uppercase',
            'hover:text-[white] transition-colors',
          )}>
          Carly Janine
        </a>
      </div>

      <nav className="flex items-center gap-4 sm:gap-8 font-sans text-body">
        {[{ href: '/bio', text: 'bio' }].map((link, index) => (
          <a
            key={index}
            href={link.href}
            className="text-jet hover:text-[white] transition-colors">
            {link.text}
          </a>
        ))}
        <InstagramButton profileTag={'carly.janine'} />
        <button
          onClick={() =>
            window.open('mailto:carlygfashionstyling@yahoo.com', '_blank')
          }
          className={classNames(
            'px-4 py-1',
            'w-max',
            'self-end',
            'border-r-4 border-r-[gray] border-b-4 border-b-[gray] border-l-2 border-l-[white] border-t-2 border-t-[white]',
            'bg-[lightgray] hover:bg-keppel hover:text-[white] transition-colors',
          )}>
          email
        </button>
      </nav>
    </header>
  );
};

export default Header;
