import classNames from 'classnames';
import React from 'react';
import { Photo } from './PhotoshootHighlight.js';
import Button from './Button.js';
import bioPic from './images/CarlyJanine.jpeg';

function Bio() {
  return (
    <div
      className={classNames(
        'flex flex-col items-center sm:items-start sm:flex-row gap-2',
        'mt-8 p-8 sm:px-32 sm:py-20',
      )}>
      <Photo key={bioPic} title={bioPic}>
        <img src={bioPic} alt={bioPic} className={classNames('')} />
      </Photo>
      <div
        className={classNames(
          'font-sans text-center sm:text-left text-body text-jet leading-loose',
          'sm:mt-2',
        )}>
        <span className={classNames('')}>Welcome to my Portfolio! I’m </span>
        <span className={classNames('font-bold uppercase')}>Carly Janine</span>
        <span className={classNames('')}>
          , a passionate + out of the box Fashion Stylist with a degree in
          Fashion Design from the Fashion Institute of Design and Merchandising
          (FIDM). My journey in the fashion industry has fueled my creativity
          for unique vision and eccentric style! i’m dedicated to expanding my
          horizons through exciting new projects and am always open to new
          innovations and opportunities. I thrive on collaborating with others
          to bring unique visions to life!{' '}
        </span>
        <div
          className={classNames(
            'flex flex-col sm:inline-flex sm:flex-row items-center gap-2',
            'mt-2',
          )}>
          <Button
            onClick={() =>
              window.open('mailto:carlygfashionstyling@yahoo.com', '_blank')
            }>
            Book a Photoshoot with Me
          </Button>
          <span className={classNames('font-bold')}>or</span>
          <Button
            onClick={() => {
              window.location.href =
                'https://docs.google.com/forms/d/e/1FAIpQLSdat7k-1Pb_rrxu5T0qI_DLFmZc19Ec0G_CUWKyoYvIU7_17g/viewform';
            }}>
            Get Personally Styled by Me
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Bio;
