import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const Container = ({ children, hasPhoto, isFirst, isLast, isBig }) => (
  <div
    className={classNames(
      'relative z-10',
      { 'translate-x-5 translate-y-4': !isFirst },
      { 'w-72': !isBig },
      { 'w-96': isBig },
      { 'p-6': hasPhoto },
      'flex justify-center items-center',
      'border-2 border-jet group-hover:brightness-[1.05] group-active:brightness-[1.05]',
      'bg-dutch/25',
    )}>
    {children}
  </div>
);

const PhotoshootHighlight = ({ children, title, path }) => (
  <Link
    to={`/photoshoots/${path}`}
    className={classNames(
      'relative group hover:cursor-pointer',
      'w-[23rem] aspect-[3/4] hover:scale-105 active:scale-105',
      'transition-all',
    )}>
    <span className={classNames('font-sans text-label text-jet')}>{title}</span>
    <Container isFirst>
      <Container>
        <Container>
          <Container hasPhoto isLast>
            <div
              className={classNames(
                'w-full h-full',
                'border-2 border-jet',
                'bg-keppel',
              )}>
              {children}
            </div>
          </Container>
        </Container>
      </Container>
    </Container>
  </Link>
);

const Photo = ({ children, title }) => (
  <div
    className={classNames(
      'relative group',
      'm-4',
      'hover:scale-105 active:scale-105',
      'transition-all',
    )}>
    <Container hasPhoto isFirst isBig>
      <div
        className={classNames(
          'w-full h-full',
          'border-2 border-jet',
          'bg-keppel',
        )}>
        {children}
      </div>
    </Container>
  </div>
);

export { PhotoshootHighlight, Photo };
