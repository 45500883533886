import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  ScrollRestoration,
} from 'react-router-dom';
import classNames from 'classnames';

import './index.css';
import Home from './Home';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';
import Photoshoot from './Photoshoot';
import HeartPatternBackground from './icons/HeartPatternBackground';
import Header from './Header';
import Bio from './Bio';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/photoshoots/:photoshootKey',
    element: <Photoshoot />,
  },
  {
    path: '/bio',
    element: <Bio />,
  },
]);

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div className={classNames('min-h-screen', 'bg-blossom')}>
      <Header />
      <HeartPatternBackground />
      <div className={classNames('relative', 'flex flex-col gap-4')}>
        <RouterProvider router={router}>
          <ScrollRestoration />
        </RouterProvider>
      </div>
    </div>
  </React.StrictMode>,
);

reportWebVitals(sendToVercelAnalytics);
