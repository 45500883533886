function ErrorIcon({ size = 'h-8 w-8', fill = 'fill-jet' }) {
  return (
    <svg
      className={size}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path className={fill} d="M85 40H99V64H85V40Z" />
      <path className={fill} d="M113 96H127V128H113V96Z" />
      <path className={fill} d="M99 64H113V96H99V64Z" />
      <path className={fill} d="M29 40H43V64H29V40Z" />
      <path className={fill} d="M15 64H29V96H15V64Z" />
      <path className={fill} d="M1 96H15V128H1V96Z" />
      <path className={fill} d="M57 52H71V84H57V52Z" />
      <path className={fill} d="M43 14H57V40H43V14Z" />
      <path className={fill} d="M57 0H71V14H57V0Z" />
      <path className={fill} d="M71 14H85V40H71V14Z" />
      <path
        className={fill}
        d="M128 113L128 128L2.15871e-06 128L2.81438e-06 113L128 113Z"
      />
      <path className={fill} d="M57 92H71V106H57V92Z" />
    </svg>
  );
}

export default ErrorIcon;
