import React, { useEffect, useRef, useState } from 'react';

function Heart({ size = 'h-8 w-8', fill = 'fill-jet' }) {
  return (
    <svg
      className={size}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={fill}
        d="M16.7871 71.3441H31.4756V86.0327H16.7871V71.3441Z"
      />
      <path
        className={fill}
        d="M31.4756 86.0327H46.1641V100.721H31.4756V86.0327Z"
      />
      <path
        className={fill}
        d="M46.1641 100.721H60.8526V115.41H46.1641V100.721Z"
      />
      <path
        className={fill}
        d="M67.1484 100.721H81.837V115.41H67.1484V100.721Z"
      />
      <path
        className={fill}
        d="M81.8359 86.0327H96.5245V100.721H81.8359V86.0327Z"
      />
      <path
        className={fill}
        d="M96.5244 71.3441H111.213V86.0327H96.5244V71.3441Z"
      />
      <path className={fill} d="M16.7871 0H41.9675V14.6885H16.7871V0Z" />
      <path className={fill} d="M86.0332 0H111.214V14.6885H86.0332V0Z" />
      <path
        className={fill}
        d="M41.9668 14.6886H56.6553V29.3772H41.9668V14.6886Z"
      />
      <path
        className={fill}
        d="M56.6572 29.3768H71.3458V44.0653H56.6572V29.3768Z"
      />
      <path
        className={fill}
        d="M56.6572 113.311H71.3458V128H56.6572V113.311Z"
      />
      <path
        className={fill}
        d="M71.3447 14.6886H86.0333V29.3772H71.3447V14.6886Z"
      />
      <path
        className={fill}
        d="M2.09863 14.6886H16.7872V71.3444H2.09863V14.6886Z"
      />
      <path
        className={fill}
        d="M111.214 14.6886H125.902V71.3444H111.214V14.6886Z"
      />
    </svg>
  );
}

export default Heart;
