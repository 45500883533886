import React from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import InstagramButton from './InstagramButton.js';
import { Photo } from './PhotoshootHighlight.js';
import { photoshoots } from './photoshoots.js';

function Photoshoot() {
  const { photoshootKey } = useParams();
  const photoshoot = photoshoots[photoshootKey];
  return (
    <div className={classNames('flex flex-col mt-16 sm:mt-2')}>
      <span
        className={classNames(
          'relative',
          'font-sans text-center text-title text-jet',
        )}>
        {photoshoot.title}
      </span>
      {photoshoot.models.length > 1 ? (
        <React.Fragment>
          <span
            className={classNames(
              'flex items-center justify-center gap-2',
              'font-sans text-center text-subtitle text-jet',
            )}>
            <strong>MODELS:</strong>
          </span>
          {photoshoot.models.map((model) => (
            <span
              key={model.ig}
              className={classNames(
                'flex items-center justify-center gap-2',
                'font-sans text-center text-subtitle text-jet',
              )}>
              {model.name}
              <InstagramButton profileTag={model.ig} />
            </span>
          ))}
        </React.Fragment>
      ) : (
        <span
          key={photoshoot.models[0].ig}
          className={classNames(
            'flex items-center justify-center gap-2',
            'font-sans text-center text-subtitle text-jet',
          )}>
          <strong>MODEL:</strong>
          {photoshoot.models[0].name}
          <InstagramButton profileTag={photoshoot.models[0].ig} />
        </span>
      )}
      <span
        key={photoshoot.photographer.ig}
        className={classNames(
          'flex items-center justify-center gap-2',
          'font-sans text-center text-subtitle text-jet',
        )}>
        <strong>PHOTOGRAPHER:</strong> {photoshoot.photographer.name}
        <InstagramButton profileTag={photoshoot.photographer.ig} />
      </span>
      <span
        key={photoshoot.stylist.ig}
        className={classNames(
          'flex items-center justify-center gap-2',
          'font-sans text-center text-subtitle text-jet',
        )}>
        <strong>STYLIST:</strong> {photoshoot.stylist.name}
        <InstagramButton profileTag={photoshoot.stylist.ig} />
      </span>
      <div className={classNames('flex flex-wrap gap-2 justify-center')}>
        {photoshoot.images.map((image) => (
          <Photo key={image} title={image}>
            <img src={image} alt={image} className={classNames('')} />
          </Photo>
        ))}
      </div>
    </div>
  );
}

export default Photoshoot;
